export default {
  config: null,
  resultsData: [],
  stats: {},
  platform: 'playtech',
  theme: {},
  totalRacers: 0,
  bettingEnabled: true,
  drawnBalls: [],
  remainingBalls: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
  ],
  lessThanSixBallsLeft: false,
  appLoaded: true,
  activeBettingTab: 0,
  drawnLeast: [],
  drawnMost: [],
  payinDisabled: false,
  payinDisabledFromSocket: false,
  round: 0,
  debugMode: false,
  events: {},
  real: '',
  skinId: '',
  username: '',
  clientType: '',
  clientPlatform: '',
  language: 'en',
  game: '',
  tempToken: '',
  ticketHistoryData: [],
  lastTicketsData: [],
  requestDisabled: false,
  companyName: 'default',
  noRebet: true,
  currentSettings: {},
  betsConfig: {
    0: { active: true },
    1: { active: true },
    2: { active: true },
    3: { active: true },
    4: { active: true },
    5: { active: true },
    6: { active: true },
    7: { active: true },
    8: { active: true },
    9: { active: true, outcomes: [] },
    10: { active: true, outcomes: [] },
    11: { active: true, outcomes: [] },
  },
  colorsDrawn: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
  betsModel: {
    items: [],
  },
  company: {
    name: '',
    currency: '',
    currencySign: '$',
    language: 'en',
    timezone: '',
  },
  player: {
    id: '',
    username: '',
    loggedIn: false,
    auth: {
      token: 'xx',
      strategy: 'tempToken',
    },
    balance: {
      time: null,
      amount: 0,
      balanceAmt: 0,
      type: 'cash',
      bonus: 2000,
      currency: '',
      locale: 'cs-CS',
      divide: 100,
    },
  },
  outcomeColors: [],
  odds: {
    0: '0.00',
    3: '0.00',
    5: '0.00',
    6: '0.00',
    7: '0.00',
    9: {
      1: '0.00',
      2: '0.00',
      3: '0.00',
      4: '0.00',
      5: '0.00',
      6: '0.00',
    },
    10: {
      0: '0.00',
      1: '0.00',
    },
    11: {
      0: '0.00',
      1: '0.00',
      2: '0.00',
    },
  },
  balls: [
    {
      ball: 0,
      tabs: [],
      type: null,
      style: {
        active: 0,
        clear: 0,
      },
    },
  ],
  bettingTabs: [
    {
      componentName: 'in-next-six',
      text: 'games_tab_next_in_six',
      active: true,
      disabled: false,
      maxBalls: 3,
    },
    {
      componentName: 'next-ball',
      text: 'games_tab_nextball',
      active: false,
      maxBalls: 2,
    },
  ],
  colorBet: null,
  expandTicketHistory: false,
  plugins: {},
  timeForRemoveNotification: 3000,
  isModalOpen: false,
  isonisList: ['MNE'],
  timeToRemoveNotification: 3000,
  isFullScreen: false,
  isBettingDisabled: true,
  showTicketCanceledModal: false,
  canceledTicket: {},
  lastSelectedBet: false,
  hideLoader: false,
  isFreeBetMode: false,
  isTicketPayinInProg: false,
};
